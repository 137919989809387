// NProgress style.
@import 'nprogress/nprogress.css';

#nprogress .bar {
  z-index: 1401;
  background: #FF5C04;
}

#nprogress .peg {
  box-shadow: 0 0 10px #FF5C04, 0 0 5px #FF5C04;
}

#nprogress .spinner-icon {
  border-top-color: #FF5C04;
  border-left-color: #FF5C04;
}
